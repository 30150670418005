<template>
  <div class="top-container" @mouseleave="productNavClose">
    <div :class="`nav-container ${productNavShow ? 'active' : ''}`">
      <div
        class="nav-item cursor-pointer"
        v-for="(i, k) in productNavList"
        :key="k"
        @click.stop.prevent="navClick(i.value)"
      >
        <img
          :class="i.class"
          :src="$utils.isMobile().mobile ? i.logoMobile : i.logo"
        />
        <div class="nav-title">
          <p>{{ i.title }}</p>
          <p>{{ i.subtitle }}</p>
        </div>
        <div :class="`nav-hover ${i.class}`" v-if="i.hover">
          <img :src="i.hover" />
        </div>
      </div>
    </div>
    <div :class="`top ${black ? 'black' : ''}`">
      <div
        v-if="!logo && showLogo"
        class="logo cursor-pointer"
        @click.stop.prevent="goIndex"
      ></div>
      <img
        v-else-if="showLogo"
        class="logo-custom cursor-pointer"
        :src="logo"
        @click.stop.prevent="goUrl"
      />
      <div class="others" v-if="!black">
        <div
          :class="`others-item product cursor-pointer ${
            productNavShow ? 'active' : ''
          }`"
          @mouseenter="productNavOpenByMouse"
          @click.stop.prevent="productNavOpen"
        >
          <span>产品</span>
          <img :src="$ossRes('/index/arrow_down.png')" />
          <!-- <div :class="`product-nav ${productNavShow ? 'active' : ''}`">
            <span class="cursor-pointer" @click.stop.prevent="goEngine"
              >川云引擎</span
            >
            <span class="cursor-pointer" @click.stop.prevent="goIndex"
              >川云活动</span
            >
          </div> -->
        </div>
        <div
          class="others-item cursor-pointer"
          @click.stop.prevent="userManualOpen"
        >
          <span>{{ $utils.isMobile().mobile ? "手册" : "用户手册" }}</span>
        </div>
        <div
          class="others-item cursor-pointer"
          @click.stop.prevent="csOpen"
          v-if="$utils.checkWxOrMini(1)"
        >
          <span>客服</span>
          <WxOpenLaunchWeapp :path="`subpackage_login_cy/cs/cs`">
            <div :style="weappStyle.div">
              <span
                :style="{
                  ...weappStyle.span,
                  position: 'absolute',
                  top: '-20px',
                }"
                >客服</span
              >
            </div>
          </WxOpenLaunchWeapp>
        </div>
        <div
          class="others-item cursor-pointer"
          @click.stop.prevent="csOpen"
          v-if="!$utils.checkWxOrMini(1)"
        >
          <span>客服</span>
        </div>
      </div>

      <div
        v-if="!userInfo && showLoginBtn"
        class="login cursor-pointer"
        @click.stop.prevent="popLoginOpen"
      >
        <span>登录</span>
      </div>

      <div
        v-if="!userInfo && isWxMini"
        class="login cursor-pointer"
        @click.stop.prevent="popLoginOpen"
      >
        <span>登录</span>
      </div>
      
      <div
        v-if="!userInfo && $utils.checkWxOrMini(1) && weappPathFinished"
        class="login cursor-pointer"
      >
        <WxOpenLaunchWeapp :path="weappPath">
          <div :style="weappStyle.div">
            <span :style="weappStyle.span">登录</span>
          </div>
        </WxOpenLaunchWeapp>
      </div>

      <div
        v-if="userInfo"
        class="login-user cursor-pointer"
        @mouseenter="loginNavOpen"
        @mouseleave="loginNavClose"
        @click="loginNavOpen"
      >
        <div class="user-avatar">
          <img v-if="userInfo.avatar" :src="userInfo.avatar" />
        </div>
        <span class="name">{{ userInfo.user_name }}</span>
        <div :class="`login-nav ${loginNavShow ? 'active' : ''}`">
          <span
            class="cursor-pointer"
            @click.stop.prevent="goManage('activity')"
            >活动管理</span
          >
          <span class="cursor-pointer" @click.stop.prevent="goManage('channel')"
            >频道管理</span
          >
          <span
            class="cursor-pointer"
            @click.stop.prevent="goManage('resource')"
            >资源管理</span
          >
          <span class="cursor-pointer" @click.stop.prevent="goManage('nft')"
            >数字藏品</span
          >
          <span class="cursor-pointer" @click.stop.prevent="logoutOpen"
            >退出登录</span
          >
        </div>
      </div>
      <action-confirm
        :show="logoutShow"
        @close="logoutClose"
        @submit="logoutCallback"
      >
        <div class="action-confirm-tips">
          <span>确定要退出登录吗？</span>
        </div>
      </action-confirm>
      <action-confirm :show="csShow" @close="csClose" :needAction="false">
        <div class="action-confirm-tips">
          <img :src="csQrcode.url" />
          <span>请使用微信扫码联系客服</span>
        </div>
      </action-confirm>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import WxOpenLaunchWeapp from "@/components/WxOpenLaunchWeapp.vue";
import ActionConfirm from "@/components/common/ActionConfirm.vue";
import { routesData, routesModuleData, routesConvert } from "@/router/index";

export default {
  components: { WxOpenLaunchWeapp, ActionConfirm },
  props: {
    black: {
      type: Boolean,
      default: false,
    },
    weappPath: {
      type: String,
      default: "",
    },
    weappPathFinished: {
      type: Boolean,
      default: false,
    },
    logo: {
      type: String,
      default: "",
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
    showLoginBtn: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loginNavShow: false,
      logoutShow: false,
      csShow: false,
      productNavShow: false,
      productNavList: [
        {
          title: "川云引擎",
          subtitle: "3D场景渲染专家",
          logo: this.$ossRes("/index/v2/logo_cy.png"),
          logoMobile: this.$ossRes("/index/v3/logo_cy.png"),
          class: "img-cy",
          value: 1,
        },
        {
          title: "IEDS",
          subtitle: "企业智慧体验展示平台",
          logo: this.$ossRes("/index/v2/logo_ieds.png"),
          logoMobile: this.$ossRes("/index/v3/logo_ieds.png"),
          class: "img-ieds",
          value: 2,
        },
        {
          title: "MeCard",
          subtitle: "元宇宙数字名片",
          logo: this.$ossRes("/index/v2/logo_mecard.png"),
          logoMobile: this.$ossRes("/index/v3/logo_mecard.png"),
          class: "img-mecard",
          value: 3,
          hover: this.$ossRes("/index/v2/mecard_nav_locate.png"),
        },
        {
          title: "川留",
          subtitle: "数字资产管理平台",
          logo: this.$ossRes("/index/v2/logo_cl.png"),
          logoMobile: this.$ossRes("/index/v3/logo_cl.png"),
          class: "img-cl",
          value: 4,
          hover: this.$ossRes("/index/v2/cl_nav_locate.png"),
        },
        {
          title: "IPVS",
          subtitle: "产业全图谱大数据可视化平台",
          logo: this.$ossRes("/index/v2/logo_ipvs.png"),
          logoMobile: this.$ossRes("/index/v2/logo_ipvs.png"),
          class: "img-ipvs",
          value: 5,
          hover: this.$ossRes("/index/v2/ipvs_nav_locate.png"),
        },
        {
          title: "平川",
          subtitle: "数字党建新空间",
          logo: this.$ossRes("/index/v2/logo_pingchuan.png"),
          logoMobile: this.$ossRes("/index/v2/logo_pingchuan.png"),
          class: "img-pingchuan",
          value: 6,
          hover: this.$ossRes("/index/v2/pingchuan_nav_locate.png"),
        },
      ],
      weappStyle: {
        div: {
          width: "100%",
          height: "100%",
          borderRadius: "12px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxSizing: "border-box",
          position: "absolute",
          left: "0",
          top: "0",
        },
        span: {
          fontSize: "14.4px",
          fontFamily: "Source Han Sans CN-Light, Source Han Sans CN",
          fontWeight: "300",
          color: "#ffffff",
        },
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["csQrcode"]),
    isWxMini() {
      let s = this.$utils.checkWxOrMini(0);
      return s;
    },
  },
  mounted() {},
  methods: {
    csOpen() {
      if (sessionStorage.getItem("isWxMini")) {
        parent.wx.miniProgram.redirectTo({
          url: `/subpackage_login_cy/cs/cs`,
        });
      } else {
        this.csShow = true;
      }
    },
    csClose() {
      this.csShow = false;
    },
    userManualOpen() {
      if (sessionStorage.getItem("isWxMini")) {
        window.open(
          process.env.VUE_APP_SUB_URL + "/pdf/川云活动平台——用户手册.png"
        );
      } else {
        window.open("https://docs.qq.com/doc/DYWxhaVVGTktIb2pu");
      }
    },
    productNavOpenByMouse() {
      if (this.$utils.isMobile().mobile) {
        return;
      }
      this.productNavOpen();
    },
    productNavOpen() {
      if (this.productNavShow) {
        this.productNavShow = false;
      } else {
        this.productNavShow = true;
      }
    },
    productNavClose() {
      this.productNavShow = false;
    },
    logoutClose() {
      this.logoutShow = false;
    },
    goManage(route) {
      this.loginNavClose();
      const router = {
        name: routesConvert(routesModuleData.manage, true).name,
        query: {
          tab: route,
        },
      };
      if (
        this.$route.name != routesConvert(routesModuleData.manage, true).name
      ) {
        this.$router.push(router);
      } else {
        this.$emit("go", route);
      }
    },
    goUrl() {
      this.loginNavClose();
      this.productNavClose();
      if (this.url) {
        if (sessionStorage.getItem("isWxMini") == 1) {
          if (this.url.indexOf(process.env.VUE_APP_BASE_DOMAIN) > -1) {
            sessionStorage.setItem("backIndex", 1);
            location.href = this.url;
          } else {
            this.$toast.fail("请在微信小程序端外访问");
          }
        } else {
          sessionStorage.setItem("backIndex", 1);
          location.href = this.url;
        }
      }
    },
    goIndex() {
      this.loginNavClose();
      this.productNavClose();
      if (this.$route.name != "Index") {
        sessionStorage.setItem("backIndex", 1);
        this.$router.replace({
          name: routesData.index.name,
        });
      }
    },
    navClick(v) {
      switch (v) {
        case 1:
          this.goEngine();
          break;
        case 2:
          this.goIEDS();
          break;
        case 3:
          this.goMecard();
          break;
        case 4:
          this.goCl();
          break;
        case 5:
          this.goIPVS();
          break;
        case 6:
          this.goPINGCHUAN();
          break;
      }
    },
    goIPVS() {
      sessionStorage.getItem("isWxMini") == 1 &&
        parent.wx.miniProgram.reLaunch({
          url: `/subpackage_exhibition/exhibitionIpvs/exhibitionIpvs`,
        });
    },
    goPINGCHUAN() {
      this.productNavClose();
      location.href = "https://ieds.idea888.com/hiragawa";
    },
    goMecard() {
      sessionStorage.getItem("isWxMini") == 1 &&
        parent.wx.miniProgram.reLaunch({
          url: `/pages/template/xiaobaozi/index/index`,
        });
    },
    goCl() {
      this.$utils.isMobile().mobile &&
        (location.href = "https://stream.xbzgj.net/home/");
    },
    goIEDS() {
      this.productNavClose();
      location.href = "https://ieds.idea888.com/";
    },
    goEngine() {
      this.productNavClose();
      location.href = "https://cy.idea888.com/";
    },
    logoutOpen() {
      this.logoutShow = true;
    },
    logoutCallback() {
      this.loginNavClose();
      this.logoutShow = false;
      this.$storage.clearToken();
      this.$store.dispatch("clearUserInfo", "");
      this.$store.dispatch("clearConfig", "");
      this.$toast.success("已退出登录");
      this.$emit("logout");
      this.goIndex();
    },
    loginNavOpen() {
      // if (!this.$utils.isMobile().wechat) {
      this.loginNavShow = true;
      // }
    },
    loginNavClose() {
      this.loginNavShow = false;
    },
    popLoginOpen() {
      this.productNavClose();
      this.$emit("open");
    },
  },
};
</script>
<style lang="scss" scoped>
.action-confirm-tips {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 360px;
    height: 360px;
    margin-top: 160px;
    margin-bottom: 46px;
  }
  span {
    font-size: 36px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    &.tips {
      font-size: 28px;
      font-family: Source Han Sans CN-Light, Source Han Sans CN;
      font-weight: 300;
      color: #999999;
      margin-top: 16px;
    }
  }
}
.top-container {
  right: 0;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 10;
}
.nav-container {
  width: 100%;
  left: 0;
  right: 0;
  height: 480px;
  background: rgba(4, 28, 65, 0.8);
  display: block;
  opacity: 0;
  position: absolute;
  top: -480px;
  transition: all 0.4s ease-in-out;
  box-sizing: border-box;
  padding-top: 144px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.active {
    top: 0;
    opacity: 1;
  }
  .nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 210px;
    position: relative;
    &:last-child {
      margin-right: 0;
    }
    img {
      flex-shrink: 0;
      margin-right: 16px;
      &.img-cy {
        width: 128px;
        height: 84px;
      }
      &.img-ieds {
        width: 180px;
        height: 84px;
      }
      &.img-mecard {
        width: 146px;
        height: 84px;
      }
      &.img-cl {
        width: 128px;
        height: 84px;
      }
      &.img-ipvs {
        width: 166px;
        height: 84px;
      }
      &.img-pingchuan {
        width: 84px;
        height: 84px;
      }
    }
    .nav-title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      p {
        font-size: 32px;
        font-family: Source Han Sans CN-Light, Source Han Sans CN;
        font-weight: 300;
        color: #ffffff;
        &:first-child {
          font-size: 40px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: 700;
          color: #ffffff;
        }
      }
    }
    .nav-hover {
      width: 338px;
      height: 358px;
      position: absolute;
      bottom: -200px;
      left: 0;
      opacity: 0;
      transition: all 0.4s ease-in-out;
      img {
        // width: 100%;
        height: 100%;
      }
      &.img-pingchuan {
        left: -250px;
      }
    }
    &:hover {
      .nav-hover {
        bottom: -380px;
        opacity: 1;
      }
    }
  }
}
.top {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 392px;
  top: 104px;
  position: absolute;
  z-index: 10;
  left: 0;
  .logo {
    flex-shrink: 0;
    width: 166.63px;
    height: 80px;
    background: url(#{$PublicEnv}/activity/top_logo_3.png);
    background-size: 100% 100%;
  }
  .logo-custom {
    flex-shrink: 0;
    width: auto;
    height: 80px;
  }
  .others {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    margin-left: 220px;
    .others-item {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      position: relative;
      margin-right: 136px;
      span {
        font-size: 40px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        font-weight: 350;
        color: #ffffff;
      }
      img {
        width: 26px;
        height: 26px;
        margin-left: 12px;
        transform: rotate(180deg);
      }
      &.product {
        transition: all 3s ease-in-out;
        &.active {
          img {
            transform: rotate(0);
          }
        }
        .product-nav {
          position: absolute;
          top: 0;
          left: 50%;
          margin-left: -125px;
          width: 250px;
          height: 248px;
          border-radius: 22px;
          background: rgba(44, 54, 96, 1);
          visibility: hidden;
          opacity: 0;
          transition: all 0.4s ease-in-out;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          box-sizing: border-box;
          padding: 48px 0;
          &.active {
            visibility: visible;
            top: 100px;
            opacity: 1;
          }
          span {
            font-size: 30px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            width: 100%;
            height: 64px;
            display: block;
            box-sizing: border-box;
            padding: 0 54px;
            line-height: 64px;
            transition: all 0.3s ease-in-out;
            &:first-child {
              background: linear-gradient(
                90deg,
                rgba(68, 86, 176, 1),
                rgba(36, 60, 115, 0) 60%
              );
            }
          }
        }
      }
    }
  }
  .login {
    flex-shrink: 0;
    width: 200px;
    height: 60px;
    border-radius: 30px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: rgba(75, 72, 83, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    position: relative;
    span {
      font-size: 36px;
      font-family: Source Han Sans CN-Light, Source Han Sans CN;
      font-weight: 300;
      color: #ffffff;
    }
  }
  .login-user {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .user-avatar {
      width: 80px;
      height: 80px;
      margin-right: 14px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
      }
    }
    span {
      font-size: 28px;
      font-family: Source Han Sans CN-Normal, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
    }
    .login-nav {
      position: absolute;
      top: 0;
      left: 0;
      width: 250px;
      // height: 360px;
      border-radius: 22px;
      background: linear-gradient(
        45deg,
        rgba(31, 39, 53, 1),
        rgba(51, 58, 72, 1)
      );
      visibility: hidden;
      opacity: 0;
      transition: all 0.4s ease-in-out;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      box-sizing: border-box;
      padding: 48px 0;
      &.active {
        visibility: visible;
        top: 100px;
        opacity: 1;
      }
      span {
        font-size: 30px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        width: 100%;
        height: 64px;
        display: block;
        box-sizing: border-box;
        padding: 0 54px;
        line-height: 64px;
        transition: all 0.3s ease-in-out;
        &:first-child {
          background: linear-gradient(
            90deg,
            rgba(68, 86, 176, 1),
            rgba(36, 60, 115, 0) 60%
          );
        }
      }
    }
  }
  &.black {
    padding: 0 160px;
    position: absolute;
    top: 60px;
    .logo {
      background: url(#{$PublicEnv}/activity/top_logo_3_black.png);
      background-size: 100% 100%;
    }
    .login-user {
      .name {
        color: rgba(55, 55, 55, 1);
      }
    }
  }
}
@media (orientation: portrait) {
  .nav-container {
    top: -5077px;
    height: auto;
    padding-top: 600px;
    padding-bottom: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 840px;
    .nav-item {
      margin-bottom: 358px;
      &:first-child {
        .nav-title {
          margin-left: 100px;
        }
      }
      img {
        flex-shrink: 0;
        margin-right: 56px;
        &.img-cy {
          width: 538px;
          height: 353px;
        }
        &.img-ieds {
          width: 635px;
          height: 353px;
        }
        &.img-mecard {
          width: 640px;
          height: 353px;
        }
        &.img-cl {
          width: 630px;
          height: 353px;
        }
        &.img-ipvs {
          width: 594px;
          height: 307px;
        }
        &.img-pingchuan {
          margin: 0 143.5px;
          width: 353px;
          height: 353px;
        }
      }
      .nav-title {
        p {
          font-size: 123px;
          &:first-child {
            font-size: 154px;
          }
        }
      }
      .nav-hover {
        width: 1024px;
        height: 1096px;
        bottom: -800px;
        z-index: 10;
        display: none;
        &.img-pingchuan {
          left: 0;
        }
      }
      &:hover {
        .nav-hover {
          display: block;
          bottom: -1136px;
          opacity: 1;
        }
      }
    }
  }
  .top {
    top: 113px;
    padding: 0 215px;
    .logo {
      width: 532px;
      height: 256px;
    }
    .logo-custom {
      flex-shrink: 0;
      width: auto;
      height: 256px;
    }
    .others {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      margin-left: 282px;
      .others-item {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: relative;
        span {
          font-size: 160px;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 350;
          color: #ffffff;
        }
        img {
          width: 184px;
          height: 184px;
          margin-left: 67px;
          transform: rotate(180deg);
        }
        &.product {
          transition: all 3s ease-in-out;
          &.active {
            img {
              transform: rotate(0);
            }
          }
          .product-nav {
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -683.5px;
            width: 1367px;
            height: 1147px;
            border-radius: 56px;
            background: rgba(44, 54, 96, 1);
            padding: 220px 0;
            &.active {
              visibility: visible;
              top: 350px;
              opacity: 1;
            }
            span {
              width: 100%;
              height: 271px;
              line-height: 271px;
              font-size: 184px;
              padding: 0 276px;
              &:first-child {
              }
            }
          }
        }
      }
    }
    .login {
      width: 717px;
      height: 266px;
      border-radius: 123px;
      span {
        font-size: 143px;
      }
    }
    .login-user {
      .user-avatar {
        width: 258px;
        height: 258px;
        margin-right: 46px;
      }
      img {
        width: 258px;
        height: 258px;
      }
      span {
        font-size: 92px;
        &.name {
          max-width: 600px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .login-nav {
        width: 1367px;
        height: fit-content;
        padding: 220px 0;
        line-height: 1500px;
        border-radius: 56px;
        right: 0;
        left: unset;
        &.active {
          top: 350px;
        }
        span {
          width: 100%;
          height: 271px;
          line-height: 271px;
          font-size: 184px;
          padding: 0 276px;
          &:first-child {
          }
        }
      }
    }
    &.black {
      padding: 0 230px;
      position: absolute;
      top: 138px;
    }
  }
  .action-confirm-tips {
    img {
      width: 1840px;
      height: 1840px;
      margin-top: 257px;
      margin-bottom: 155px;
    }
    span {
      font-size: 184px;
      &.tips {
        font-size: 143px;
        margin-top: 128px;
      }
    }
  }
}
</style>
